<template>
    <app-layout>
        <div class="esim-home">
            <div class="esim-home__wrapper">
                <div class="esim-home__top">
                    <img v-if="currentRoute !== 'telecomsquare' && currentRoute !== 'entelecomsquare'" src="@/assets/images/virtual-sim.jpg" alt="">
                    <img v-if="currentRoute === 'telecomsquare' || currentRoute === 'entelecomsquare'" src="@/assets/images/jp-home.jpg" alt="">
                    <div v-if="currentRoute !== 'telecomsquare' && currentRoute !== 'entelecomsquare'">
                        <div style="margin-bottom: 10px;">
                            <h2>What is an eSIM?</h2>
                            <p>An eSIM is an industry-standard digital SIM that allows you to activate a cellular plan
                                from your carrier without having to use a physical SIM.</p>
                        </div>
                        <div>
                            <h2>Benefit of eSIMs:</h2>
                            <p>-Get set up before you arrive to your destination.</p>
                            <p>-No invalid / defected SIM card.</p>
                            <p>-No need of physically swapping with already existing SIM card</p>
                            <p>-You can purchase it at any time, use it at any time.</p>
                        </div>
                        <p>**Please make sure that your mobile device is eSIM compatible before the use.</p>
                    </div>
                </div>
                <div class="esim-home__questions">
                    <div
                        v-for="item in currentRoute === 'esim' ? questions : currentRoute === 'entelecomsquare' ? questionsForEnTelecom : questionsForTelecom">
                        <h2>{{ item.title }}</h2>
                        <p v-for="note in item.notes">{{ note }}</p>
                        <img v-if="item.id === 4 && currentRoute !== 'telecomsquare'" src="@/assets/images/map.jpg" alt="">
                        <img v-if="item.id === 4 && currentRoute === 'telecomsquare'" src="@/assets/images/map-jp.jpg" alt="">
                    </div>
                </div>
                <div class="esim-home__forms" v-if="currentRoute === 'esim'">
                    <h2>Contact us</h2>
                    <div class="esim-home__forms__top">
                        <input v-model="form.name" placeholder="Name" type="text">
                        <input v-model="form.email" placeholder="Email" type="email">
                    </div>
                    <div>
                        <input v-model="form.eid" class="eid-input"
                               placeholder="EID or Activation code (if you already got eSIM)" type="text">
                        <textarea v-model="form.message" placeholder="Message (please leave the message here)"/>
                    </div>
                    <button @click="sendMessage"
                            style="margin-top: 10px; padding: 10px; background-color: #ea0a8e; color: #fff; border: none; cursor: pointer">
                        {{ loading ? 'Sending...' : 'Send message' }}
                    </button>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "Home",
    components: {AppLayout},
    data() {
        return {
            questions: [
                {
                    id: 1,
                    title: 'About T-Mobile eSIM',
                    notes: ['■ T-Mobile is one of the three major American carriers.\n' +
                    'T-mobile has the fastest 5G network in the United States and has been the most rapidly growing American mobile telecommunications carrier in recent years.', '**If your device doesn\'t receive 5G network, it will receive 4G LTE automatically.', '■ Recommended for customers who are looking for the best price and Internet connection!', 'Unlimited tethering!', '**Devices connected via tethering with receive 3G speeds']
                },
                {
                    id: 2,
                    title: 'T-Mobile\'s main frequency band',
                    notes: ['■ 5G: Band n41 (2.5 GHz), Band n258 (24 GHz), Band n260 (39 GHz), Band n261 (28 GHz)', '(if your device is available to receive 5G data)', '■ 4G LTE: Band 2 (1900 MHz), Band 5 (850 MHz), Band 4 (1700/2100 MHz), Band 66 (Extension of band 4 on 1700/2100 MHz).']
                },
                {
                    id: 3,
                    title: 'Our eSIM products',
                    notes: ['■ Unlimited talk and text within the U.S. (Mobile + Landline)', '■ No international calling', '**Please note that if you call or receive calls from oversease, your eSIM will be suspende BEFORE its expiration date and will be charged additional fees.', '**To call internationally, please use third-party apps, such as Messenger, WhatsApp, LINE, etc. (International SMS will result in eSIM suspension and additional fees)', '■ Unlimited Text in the United States (International SMS is not allowed)', '■ Unlimited 5G data (if your device is available to receive 5G data)', '■ Unlimited High -speed (4G LTE) data', '■ Unlimited tethering (the speed is 3G for devices using tethering)']
                },
                {
                    id: 4,
                    title: 'T-Mobile Coverage',
                    notes: ['■ T-Mobile has a strong network in mainland USA, and major cities in Hawaii (not eligible for Alaska, Guam, Saipan, Canada, and Mexico)', '■ Please check the network status of area < T-Mobile Coverage Map > where you go before purchasing ↓']
                },
            ],
            questionsForTelecom: [
                {
                    id: 1,
                    title: 'T-Mobileについて',
                    notes: [
                        '■T-Mobileはアメリカ3大キャリアの１つです。現在全米で最も速い5Gネットワークを誇っており、近年で最も急成長しているモバイルキャリアです。',
                        '■【安心、安定、コスパ】重視のお客様にオススメです！',
                        '■【無制限テザリング】付き！',
                        '**テザリングを使用したデバイスの速度は3Gです'
                    ]
                },
                {
                    id: 2,
                    title: 'T-Mobileのメイン周波数帯域',
                    notes: [
                        '■ 5G: Band n41 (2.5 GHz), Band n258 (24 GHz), Band n260 (39 GHz), Band n261 (28 G',
                        '（デバイスが5Gデータを受信できる場合）',
                        '■ 4G LTE: Band 2 (1900 MHz), Band 5 (850 MHz), Band 4 (1700/2100 MHz), Band 66 (Extension of band 4 on 1700/2100 MHz).',
                    ]
                },
                {
                    id: 3,
                    title: 'プラン内容',
                    notes: [
                        '■アメリカ国内通話（携帯＋固定電話）発/着信し放題',
                        '■国際通話は不可能',
                        '※日本などへの国際通話発信は原則禁止です。海外へ発信されますと回線のご利用期限途中での停止や、追加料金が発生しますのでご注意下さい。電話回線を使用しないアプリ電話等でしたら無制限使い放題です。',
                        '■アメリカ国内でSMS送受信し放題（国際SMS不可）',
                        '■無制限の5Gデータ（5G対応デバイスのみ）',
                        '■無制限の4G LTE データ',
                        '■無制限のテザリング（テザリングを使用したデバイスの速度は3Gです）',
                    ]
                },
                {
                    id: 4,
                    title: 'T-Mobile対応地域',
                    notes: [
                        '■T-Mobileは、アメリカ本土、ハワイの主要都市に強いネットワークを持っています（アラスカ、グアム、サイパン、カナダ、メキシコは対象外）',
                        '■ご購入前に必ずご利用地域のネットワーク状況（T-Mobile coverage map）をご確認ください↓',
                    ]
                },
                {
                    id: 5,
                    title: 'Contact Us',
                    notes: [
                        'Eメール：activation@gttusa.net',
                        '営業時間：9:00AM to 5:30PM（米国西海岸時間）',
                    ]
                },
            ],
            questionsForEnTelecom: [
                {
                    id: 1,
                    title: 'About T-Mobile eSIM',
                    notes: ['■ T-Mobile is one of the three major American carriers.\n' +
                    'T-mobile has the fastest 5G network in the United States and has been the most rapidly growing American mobile telecommunications carrier in recent years.', '**If your device doesn\'t receive 5G network, it will receive 4G LTE instead automatically.', 'Recommended for customers who are looking for the best price and Internet connection!', '■ Unlimited tethering!', '**Devices connected via tethering with receive 3G speeds']
                },
                {
                    id: 2,
                    title: 'T-Mobile\'s main frequency band',
                    notes: ['■ UC 5G: Band n41 (2.5 GHz), Band n258 (24 GHz), Band n260 (39 GHz), Band n261 (28 GHz)', '(if your device is available to receive 5G data)', '■ 4G LTE: Band 2 (1900 MHz), Band 5 (850 MHz), Band 4 (1700/2100 MHz), Band 66 (Extension of band 4 on 1700/2100 MHz).', '■3G：WCDMA/HSPA+ 1900/2100 AWS (Band 4)']
                },
                {
                    id: 3,
                    title: 'Our eSIM products',
                    notes: ['■ Unlimited talk and text within the U.S. (mobile + landline)', '■ No international calling or incoming call is possible', '** Please note that if you call or receive calls from overseas, you will be suspended BEFORE your plan expiration date and an additional charge. \n', 'Please use APPs, such as Messenger and WhatsApp, to call and DO NOT use telephone lines.', '■ Unlimited Text in the United States (International SMS is not allowed)', '■ Unlimited 5G data (if your device is available to receive 5G data)', '■ Unlimited High -speed (4G LTE) data', '■ Unlimited tethering (the speed is 3G for devices using tethering)']
                },
                {
                    id: 4,
                    title: 'T-mobile support area',
                    notes: ['■ T-Mobile has a strong network in mainland USA, and major cities in Hawaii (not eligible for Alaska, Guam, Saipan, Canada, and Mexico)', '■ Please check the network status of area < T-Mobile Coverage Map > where you go before purchasing ↓']
                },
                {
                    id: 5,
                    title: 'Contact Us',
                    notes: ['E-maile：activation@gttusa.net', 'Open Hours：9:00AM to 5:30PM (PT)']
                },
            ],
            form: {
                name: '',
                email: '',
                eid: '',
                message: ''
            },
            loading: false,
            currentRoute: ''
        }
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },
    methods: {
        sendMessage() {
            if (this.form.name && this.form.email && this.form.message) {
                this.loading = true
                this.$axios.post(`/contacts/contact-us`, this.form)
                    .then(res => {
                        this.form.name = ''
                        this.form.email = ''
                        this.form.eid = ''
                        this.form.message = ''
                        this.loading = false
                    })
            }
        }
    }
}
</script>

<style lang="scss">
.esim-home {
    padding-top: 60px;
    background-color: #7CCAFF;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h2 {
        margin: 0;
    }

    p {
        margin: 0;
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
    }

    &__top {
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        h2 {
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 5px;
        }

        img {
            max-width: 612px;
            width: 100%;
        }
    }

    &__questions {
        div {
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 10px;
            background-color: #ea0a8e;
            color: #fff;
            padding: 5px;
        }

        p {
            margin-bottom: 10px;
            margin-left: 15px;
        }

        img {
            max-width: 438px;
            width: 100%;
            margin: 0 auto;
            display: block;
        }
    }

    &__forms {
        h2 {
            margin: 10px 0;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            input {
                padding: 10px;
                width: 100%;
                margin-bottom: 25px;

            }
        }

        .eid-input {
            margin: 0 0 25px 0;
            padding: 10px;
            width: -webkit-fill-available;
        }

        textarea {
            width: -webkit-fill-available;
            height: 200px;
            padding: 10px;
        }
    }
}
</style>