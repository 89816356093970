<template>
    <div
        ref="activation-form"
        class="activation__form"
    >
        <div class="activation__progress">
            <img src="../../assets/images/activation-progress-1.png" alt="">
        </div>

        <div class="activation__form-title">
            <h1>Activation Details:</h1>
        </div>

        <div class="activation__form-img mobile">
            <img src="../../assets/images/sim-card-explained-en.png" alt="">
        </div>

        <div class="activation__form-inputs">
            <div class="activation__form-input">
                <label
                    for="sim_number"
                    :class="{'is_invalid': errors && errors.iccid}"
                >
                    Insert Activation Code
                </label>
                <input
                    type="text"
                    id="sim_number"
                    v-model="form.iccid"
                    @input="inputIccid"
                >
                <span
                    v-if="errors && errors.iccid"
                    class="error-message"
                >
                    {{ errors.iccid[0] }}
                </span>
            </div>
            <div class="activation__form-input">
                <label for="email">E-mail</label>
                <input
                    type="email"
                    id="email"
                    v-model="form.email"
                >
            </div>
            <div class="activation__form-input">
                <label
                    for="start_date"
                    :class="{'is_invalid': errors && errors.start_date}"
                >
                    Start Date
                </label>
                <input
                    type="date"
                    id="start_date"
                    v-model="form.start_date"
                    @change="startDateChanged"
                >
                <span
                    v-if="errors && errors.start_date"
                    class="error-message"
                >
                    {{ errors.start_date[0] }}
                </span>
            </div>
            <div class="activation__form-group">
                <div class="activation__form-input">
                    <label for="end_date">End Date</label>
                    <input
                        type="date"
                        id="end_date"
                        disabled
                        v-model="form.end_date"
                    >
                </div>
                <div class="activation__form-input">
                    <label for="days">Days Available</label>
                    <input
                        type="number"
                        id="days"
                        disabled
                        v-model="form.available_days"
                    >
                </div>
            </div>
            <div class="activation__form-input">
                <template v-if="loading">
                    <button
                        disabled
                    >
                        Loading...
                    </button>
                </template>
                <template v-if="!loading">
                    <button
                        @click.prevent="submit"
                    >
                        Next
                    </button>
                </template>
            </div>
        </div>

        <div class="activation__form-img">
            <img src="../../assets/images/sim-card-explained-en.png" alt="">
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import _ from 'lodash';

    export default {
        name: "ActivationForm",

        data() {
            return {
                form: {
                    id: null,
                    iccid: '',
                    email: '',
                    start_date: '',
                    end_date: '',
                    available_days: 0,
                },

                errors: null,
                loading: false,
            }
        },

        created() {
            this.initData();
        },

        mounted() {
            // this.$smoothScroll({
            //     scrollTo: this.$refs['activation-form'],
            //     duration: 1000,
            // });
        },

        methods: {
            initData() {
                let activationFormData = localStorage.getItem('activationFormData');

                if (activationFormData) {
                    activationFormData = JSON.parse(activationFormData);
                    this.$store.dispatch('setActivationFormData', activationFormData);
                    this.form.id = activationFormData.id;
                    this.form.iccid = activationFormData.iccid;
                    this.form.start_date = moment(activationFormData.start_date).format('YYYY-MM-DD');
                    this.form.end_date = moment(activationFormData.end_date).format('YYYY-MM-DD');
                    this.form.available_days = activationFormData.available_days;
                    this.form.email = activationFormData.email;
                }
            },

            inputIccid: _.debounce(function (e) {
                this.errors = null;
                this.form.id = null;

                if (e) {
                    this.$axios.get(`/sim-cards/${e.target.value}/check-by-iccid`)
                        .then(res => {
                            if (res) {
                                this.form.available_days = res.data.valid_days;
                            }
                        })
                        .catch(e => {
                            if (e.status === 422) {
                                this.errors = e.data.errors;
                            } else if (e.status === 404) {
                                this.errors = {
                                    iccid: [e.data.message]
                                }
                            } else {
                                console.log(e);
                            }
                        });
                }
            }, 1000),

            startDateChanged() {
                let startDate = moment(this.form.start_date);
                let endDate = startDate.add(this.form.available_days, 'day');

                this.form.end_date = endDate.format('YYYY-MM-DD');
            },

            submit() {
                this.loading = true;

                this.$axios.post('/sim-card-activations/store', this.form)
                    .then(res => {
                        if (res) {
                            this.$store.dispatch('setActivationFormData', res.data);
                            localStorage.setItem('activationFormData', JSON.stringify(res.data));
                            this.$router.push({name: 'activation_details'});
                        }

                        this.loading = false;
                    })
                    .catch(e => {
                        if (e.status === 422) {
                            this.errors = e.data.errors;
                        } else {
                            console.log(e);
                        }

                        this.loading = false;
                    });
            }
        }
    }
</script>

<style lang="scss">
    .activation {
        &__form {
            padding: 2rem 4rem;
            display: flex;
            flex-wrap: wrap;

            &-title {
                width: 100%;
                margin: 0 0 2rem 0;

                h1 {
                    margin: 0;
                    font-size: 1.5rem;
                    font-weight: 400;
                }
            }

            &-inputs {
                width: 40%;
                margin: 0 2rem 0 0;
            }

            &-group {
                display: flex;
            }

            &-input {
                margin: 0 .5rem 1rem 0;
                width: 100%;

                &:last-child {
                    margin-right: 0;
                }

                label {
                    display: inline-block;
                    margin: 0 0 .5rem 0;
                    color: #686868;
                    font-weight: 500;
                    font-size: .9rem;

                    &.is_invalid {
                        color: red;
                    }
                }

                input {
                    box-sizing: border-box;
                    padding: .7rem .8rem;
                    border: 1px solid #EAEBEB;
                    background: #EAEBEB;
                    border-radius: 5px;
                    width: 100%;

                    &#start_date, &#end_date {
                        padding: .54rem .8rem;
                    }
                }

                .error-message {
                    font-style: italic;
                    font-size: .8rem;
                    color: red;
                }

                button {
                    background: #1094EB;
                    border: 1px solid #1094EB;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: .7rem .8rem;
                    width: 100%;
                    cursor: pointer;
                }
            }

            &-img {
                width: 50%;

                &.mobile {
                    display: none;
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .activation {
            &__form {
                padding: 2rem 2rem;

                &-inputs {
                    width: 100%;
                    margin: 0;
                }

                &-img {
                    display: none;
                    width: 100%;

                    &.mobile {
                        display: block;
                    }
                }
            }
        }
    }
</style>