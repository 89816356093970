<template>
    <app-layout>
        <div class="how-to" ref="HowToAdd">
            <div class="how-to__wrapper">
                <h2 style="background-color: #ff80ca; padding: 10px">For iPhone users</h2>
                <hr>
                <div class="how-to__notes" v-for="item in notes" :key="item.id">
                    <h2 @click="toggleTitle(item)">{{ item.id }}. {{ item.title }}</h2>
                    <p v-if="!item.showNote" v-for="(note, i) in item.note">{{ item.id === 2 ? i + 1 + '.' : '▶' }}
                        {{ note }}</p>
                </div>
                <h2 style="color: #F30B0B; margin-bottom: 55px;">* Please DO NOT delete eSIM; otherwise, you will not be
                    able to add eSIM again </h2>
                <hr>
                <h2 style="background-color: #ffe980; padding: 10px">For Android users</h2>
                <hr>
                <div class="how-to__notes" v-for="item in notes_for_android" :key="item.id">
                    <h2 @click="toggleTitle(item)">{{ item.id }}. {{ item.title }}</h2>
                    <p v-if="!item.showNote" v-for="(note, i) in item.note" :style="{ 'font-size': (item.id === 7 && i >= 3) ? '18px' : 'inherit' }">
                        {{
                            item.id === 6 || item.id === 4 || item.id === 3 ? '' : (item.id === 7 && i >= 3) ? '**' : '▶'
                        }}
                        {{ note }}
                    </p>
                </div>
                <h2 style="color: #F30B0B">* Please DO NOT delete eSIM; otherwise, you will not be able to add eSIM
                    again </h2>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "HowToAdd",
    components: {AppLayout},
    data() {
        return {
            notes: [
                {id: 1, title: 'Make sure you have access to the internet on your device', note: ''},
                {
                    id: 2,
                    title: 'Go to the settings on your phone and follow the instructions below',
                    note: ['Turn off Airplane mode', 'Turn on Cellular', 'Restart your device']
                },
                {
                    id: 3,
                    title: 'Connect Internet the one of the following ways',
                    note: ['If there is a notification from T-Mobile: Press the notification and connect WiFi (the place is within the home screen or setting)', 'When you deleted the notification: "Settings" → "Cellular" → "Add eSIM"', 'When the QR Code option is displayed: "Use QR Code" → "Enter Details Manually" → Enter SM-DP-address: "T-MOBILE.GDSB.NET" → "Next" on the upper right']
                },
                {
                    id: 4,
                    title: 'Select a preferred line of communication and message (since it will be changed in settings of 4, either option is possible)',
                    note: ''
                },
                {
                    id: 5,
                    title: 'Set cellular',
                    note: ['If you are in your country of residence: Turn off "Secondary line (T-Mobile line)"', 'When you are in the US: Turn off the "Existing line (the line you usually use in your country"']
                },
            ],
            notes_for_android: [
                {id: 1, title: 'Make sure you have access to the internet on your device', note: ''},
                {
                    id: 2,
                    title: 'Go to the "Setting" on your phone and follow the instructions below',
                    note: ['Turn off Airplane mode', 'Turn on Cellular', 'Restart your device']
                },
                {
                    id: 3,
                    title: 'Go to the “Connection”→Tap “SIM manager”→ Tap “Add eSIM”',
                    note: ['[If it shows phone careers,  please choose “T-Mobile”]', '(Names would be different depending on the model)"', '* If the QR code option is displayed, please input the following manually*', '< SM-DP+Address :T-MOBILE.GDSB.NET >']
                },
                {
                    id: 4,
                    title: 'Select a phone number',
                    note: ['(since it will be changed in the setting of 5, either options are possible)']
                },
                {
                    id: 5,
                    title: 'Set up Cellular',
                    note: ['when you are in the country of residence: Turn off "T-MOBILE line"\n', 'If you are in the United States: Turn off the existing line which you usually use in your country).\n']
                },
                {
                    id: 6,
                    title: 'For customers set up while in their country of residence:',
                    note: ['After arriving in the United States, please turn on the "T-MOBILE line," and turn off the "the existing line which you usually use in your country”\n']
                },
                {
                    id: 7,
                    title: 'If you can not connect to Internet after waiting about 5 minutes, you will need an APN (mobile network) setting.\n',
                    note: ['Input the following information to the new APN settings manually\n' +
                    '            NAME: "T-Mobile" APN": "Fast.t-mobile.com"\n', 'Tap the save button and select the corresponding APN from the APN list', 'Restart your device.\n', 'Please visit T-Mobile\'s official website to see the instructions about APN settings for your device\'s model. Choose your device\'s model and service "APN"', 'https://www.t-mobile.com/support/tutorials/']
                },
            ]
        }
    },
    methods: {
        toggleTitle(item) {
            item.showNote = !item.showNote;
        }
    },
}
</script>

<style lang="scss">
.how-to {
    padding-top: 60px;
    background-color: #7CCAFF;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h2 {
        margin: 0;
    }

    p {
        margin: 0;
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
    }

    &__notes {
        margin-bottom: 10px;

        h2 {
            cursor: pointer;
            margin-bottom: 8px;
            font-weight: 500;
            @media screen and (max-width: 768px) {
                font-size: 20px;
            }

            &:hover {
                color: #1094EB;
            }
        }

        p {
            margin-left: 28px;
            margin-bottom: 5px;
        }
    }
}
</style>