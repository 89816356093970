<template>
    <div class="recharge__payment">
        <div class="recharge__progress">
            <img src="../../assets/images/recharge-progress-3.png" alt="">
        </div>

        <div class="recharge__payment-title">
            <h1>Recharge Payment:</h1>
        </div>

        <div class="recharge__payment-img mobile">
            <img src="../../assets/images/recharge-visa.png" alt="">
        </div>

        <loader-round v-show="loading" />

        <div
            v-show="!loading"
            class="recharge__payment-body"
        >
            <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
            </div>

            <button
                v-if="!btnLoading"
                @click.prevent="submit"
            >
                Checkout
            </button>

            <button
                v-if="btnLoading"
                disabled
            >
                Loading...
            </button>
        </div>

        <div class="recharge__payment-img">
            <img src="../../assets/images/recharge-visa.png" alt="">
        </div>
    </div>
</template>

<script>
import LoaderRound from "@/components/LoaderRound";
export default {
    name: "RechargePayment",
    components: {LoaderRound},
    data () {
        return {
            stripe: Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY),
            stripeElements: null,
            loading: true,
            btnLoading: false,
            error: null
        }
    },

    mounted() {
        this.initPayment();
    },

    methods: {
        initPayment() {
            this.loading = true;

            this.$axios.post('/sim-card-recharges/payment')
                .then(res => {
                    let clientSecret = res.data.clientSecret;
                    this.stripeElements = this.stripe.elements({ clientSecret });

                    const paymentElement = this.stripeElements.create("payment");
                    paymentElement.mount("#payment-element");
                    this.loading = false;
                })
        },

        async submit() {
            this.btnLoading = true;

            const { error } = await this.stripe.confirmPayment({
                elements: this.stripeElements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: process.env.VUE_APP_URL,
                },
            });

            this.btnLoading = false;

            if (error.type === "card_error" || error.type === "validation_error") {
                showMessage(error.message);
            } else {
                showMessage("An unexpected error occured.");
            }
        }
    }
}
</script>

<style lang="scss">
.recharge {
    &__progress {
        width: 100%;
        margin: 0 0 2rem 0;

        img {
            width: 100%;
        }
    }

    &__payment {
        padding: 2rem 4rem;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &-title {
            width: 100%;
            margin: 0 0 2rem 0;

            h1 {
                margin: 0;
                font-size: 1.5rem;
                font-weight: 400;
            }
        }

        &-body {
            flex: 1;

            button {
                background: #1094EB;
                border: 1px solid #1094EB;
                color: #fff;
                font-size: 1rem;
                font-weight: 500;
                border-radius: 5px;
                padding: .7rem .8rem;
                width: 100%;
                cursor: pointer;
                margin: 1.2rem 0 0 0;
            }
        }

        &-img {
            width: 50%;
            padding: 0 2rem;

            &.mobile {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .recharge {
        &__payment  {
            padding: 2rem 2rem;

            &-body {
                width: 100%;
                margin: 0;
            }

            &-img {
                display: none;
                width: 100%;
                margin: 0 0 1rem 0;

                &.mobile {
                    display: block;
                }
            }
        }
    }
}
</style>
