<template>
    <app-layout>
        <div class="jotform" ref="esim-scroll">
            <div class="jotform__wrapper">
                <iframe
                    v-if="currentRoute === 'esim'"
                    id="JotFormIFrame-232298178705162"
                    title="Japan SIM/eSIM Purchase form"
                    onload="window.parent.scrollTo(0,0)"
                    allowtransparency="true"
                    allowfullscreen="true"
                    allow="geolocation; microphone; camera"
                    src="https://form.jotform.com/232298178705162"
                    frameborder="0"
                    style="min-width: 100%; max-width:100%; border:none; height: 2100px"
                >
                </iframe>
                <iframe
                    v-else
                    id="JotFormIFrame-231706364150146"
                    title="Japan SIM/eSIM Purchase form"
                    onload="window.parent.scrollTo(0,0)"
                    allowtransparency="true"
                    allowfullscreen="true"
                    allow="geolocation; microphone; camera"
                    src="https://form.jotform.com/231706364150146"
                    frameborder="0"
                    style="min-width: 100%; max-width:100%; border:none; height: 2500px"
                >
                </iframe>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
    name: "Esim",
    components: {AppLayout},
    data() {
        return {
            currentRoute: '',
            esimID: '232298178705162',
            telecomID: '231706364150146'
        }
    },
    mounted() {
        // this.handleIFrameMessage()
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },

}
</script>

<style lang="scss">
.jotform {
    padding-top: 60px;
    background-color: #7CCAFF;
    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        border-radius: 10px;
    }
}
</style>