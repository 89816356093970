<template>
    <div class="activation__details">
        <div class="activation__progress">
            <img src="../../assets/images/recharge-progress-2.png" alt="">
        </div>

        <div class="activation__details-title">
            <h1>Recharge Summary:</h1>
        </div>

        <div class="activation__form-img mobile">
            <img src="../../assets/images/recharge-t-mobile.png" alt="">
        </div>

        <div
            v-if="rechargeFormData"
            class="activation__details-items"
        >
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Insert Activation Code
                </div>
                <div class="activation__details-item-des">
                    {{ rechargeFormData.iccid }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Email
                </div>
                <div class="activation__details-item-des">
                    {{ rechargeFormData.email }}
                </div>
            </div>
            <div class="activation__details-group">
                <div class="activation__details-item">
                    <div class="activation__details-item-title">
                        Start Date
                    </div>
                    <div class="activation__details-item-des">
                        {{ rechargeFormData.start_date }}
                    </div>
                </div>
                <div class="activation__details-item">
                    <div class="activation__details-item-title">
                        End Date
                    </div>
                    <div class="activation__details-item-des">
                        {{ rechargeFormData.end_date }}
                    </div>
                </div>
            </div>
            <div class="activation__details-group">
                <div class="activation__details-item">
                    <div class="activation__details-item-title">
                        Days
                    </div>
                    <div class="activation__details-item-des">
                        {{ rechargeFormData.available_days }}
                    </div>
                </div>
                <div class="activation__details-item">
                    <div class="activation__details-item-title">
                        Price
                    </div>
                    <div class="activation__details-item-des">
                        {{ rechargeFormData.price }} $
                    </div>
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-button">
                    <template v-if="loading">
                        <button
                            disabled
                        >
                            Loading...
                        </button>
                    </template>
                    <template v-if="!loading">
                        <button
                            @click.prevent="submit"
                        >
                            Payment
                        </button>
                    </template>
                </div>
            </div>
        </div>

        <div class="activation__details-img">
            <img src="../../assets/images/recharge-t-mobile.png" alt="">
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "RechargeDetails",

    data() {
        return {
            rechargeFormData: null,
            loading: false,
        }
    },

    created() {
        this.initData();
    },

    methods: {
        initData() {
            let rechargeFormData = this.$store.getters.rechargeFormData;

            if (rechargeFormData) {
                this.rechargeFormData = rechargeFormData;
                this.rechargeFormData.start_date = moment(rechargeFormData.start_date).format('YYYY-MM-DD');
                this.rechargeFormData.end_date = moment(rechargeFormData.end_date).format('YYYY-MM-DD');
            } else {
                this.$router.push({name: 'recharge_form'});
            }
        },

        submit() {
            this.$router.push({name: 'recharge_payment'});
        }
    },
}
</script>

<style lang="scss">
    .activation {
        &__details {
            &-group {
                display: flex;
                margin: 0 0 1rem 0;

                .activation__details-item {
                    margin: 0 2rem 0 0;
                }
            }
        }
    }
</style>