<template>
    <app-layout>
        <div class="faq">
            <div class="faq__wrapper">
                <div class="faq__questions">
                    <h2 style="background-color: #ffe980;" v-for="item in this.currentRoute === 'esim' ? questions : currentRoute === 'entelecomsquare' ? questionsEn : questionsForTelecom" @click="scrollTo(item)">{{ item.question }}</h2>
                </div>
                <div class="faq__answers">
                    <div v-for="item in this.currentRoute === 'esim' ? questions : currentRoute === 'entelecomsquare' ? questionsEn : questionsForTelecom">
                        <h4 style="font-size: 24px">{{ item.question }}</h4>
                        <div class="faq__answers-title" v-for="answer in item.answers">
                            <h3 :ref="'faqTitle' + item.id">{{ answer.title }}</h3>
                            <p v-for="note in answer.notes">{{ note }}</p>
                            <p v-if="item.id === 1 && currentRoute === 'esim'"><router-link to="/esim/compatible">③ Ensure your device is eSIM compatible</router-link></p>
                            <p v-if="item.id === 1 && currentRoute === 'esim'">④ If your device is still not coneccted, please contact us and ecplain your problem to our customer service staff</p>
                            <a v-if="item.id === 2 && answer.link" href="http://gosimcard.com/esim/compatible">Please check here</a>
                            <img style="display: flex; justify-content: center;" v-if="item.id === 4 && currentRoute !== 'telecomsquare'" src="@/assets/images/map.jpg" alt="">
                            <img style="margin-left: 10px;" v-if="item.id === 4 && currentRoute === 'telecomsquare'" src="@/assets/images/map-jp.jpg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "Faq",
    components: {AppLayout},
    methods: {
        // scrollTo(item) {
        //     const screenWidth = window.innerWidth;
        //     if (screenWidth <= 768) {
        //         this.$smoothScroll({
        //             scrollTo: this.$refs[item.id],
        //             duration: 1000,
        //             offset: item.offset * 2,
        //         });
        //     } else {
        //
        //         this.$smoothScroll({
        //             scrollTo: this.$refs[item.id],
        //             duration: 1000,
        //             offset: item.offset,
        //         });
        //     }
        // }
        scrollTo(item) {
            if (item) {
                this.$smoothScroll({
                    scrollTo: this.$refs['faqTitle' + item.id][0],
                    duration: 1000,
                    offset: -100,
                });
            } else {
                console.error(`Элемент с id '${item.id}' не найден.`);
            }
        }
    },
    data() {
        return {
            currentRoute: '',
            questions: [
                {
                    id: 1,
                    question: "⯁I Can't Connect to Internet in the USA",
                    answers: [
                        {
                            id: 1,
                            title: 'For iPhone users',
                            notes: ['① Ensure your eSIM IMEI matches the IMEI in the Activation Request', '② If the WiFi is weak and hinders communication during the connection: Turn off the Wifi and restart your device']
                        },
                        {
                            id: 2,
                            title: 'For Android users',
                            notes: ['① Ensure your eSIM IMEI matches the IMEI in the Activation Request', '② If the WiFi is weak and hinders communication during the connection: Turn off the Wifi and restart your device']
                        }
                    ]
                },
                {
                    id: 2, ref: 'second', question: '⯁Is my Device Supported', answers: [
                        {id: 1, title: '', link: true}
                    ]
                },
                {
                    id: 3,
                    ref: 'third',
                    question: '⯁Where Can I Find my USA Phone Number',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ["Phone numbers are automatically given on the day of activation. Upon arrival in the U.S., please check the number in your phone's settings.", '■iPhone: "Settings" → "Cellular"', '■Android: "Settings" → "About Phone" → "Phone number"', '* If you would like to know your USA number before traveling, please set the start date before your actual arrival date. ']

                        }
                    ]
                },
                {
                    id: 4,
                    ref: 'fourth',
                    question: '⯁Will I Have Access to Internet Across the USA',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['Please make sure to check the network status of your area ( by Coverage Map) before purchasing.']
                        }
                    ]
                },
                {
                    id: 5,  ref: 'fifth', question: '⯁How Can I See if my Device in Unlocked', answers: [
                        {
                            id: 1,
                            title: '■ For iPhone ( * iOS14 or later):',
                            notes: ['Go to "Settings" → "General" → "About" → "Carrier Lock"', 'If it says [No SIM restrictions], then the SIM is unlocked.']
                        },
                        {
                            id: 2,
                            title: '■For  Xperia:',
                            notes: ['Enter "*#*#7465625#*#***" to the dial pad on your phone', 'Select "SIM lock," and if it shows', '［］Network subset　→　SIM is unlocked', '［×］Network subset　→ SIM is locked and you need to unlock it']
                        },
                        {
                            id: 3,
                            title: '■For other Android:',
                            notes: ['Go to "Settings" → "About phone" → "SIM lock status" is', '[ Locked ] : SIM is locked, please unlock the SIM at your mobile carrier', '[ Permitted or Unlocked ]: SIM is unlock', '']
                        },
                    ]
                },
                {
                    id: 6, ref: 'sixth', question: '⯁Do I Need to Enable Data Roaming', answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['If you turn it on, the optimal radio waves where you are will be automatically selecedt, so you may be able to get a better internet connection.', 'Our plan includes data roaming, and there is no additional charge for it.']
                        }
                    ]
                },
                {
                    id: 7, ref: 'seventh', question: '⯁Can I Call Internationally', answers: [
                        {
                            id: 1,
                            title: '■ T-mobile',
                            notes: ['* International calls are prohibited, international calls to Japan and the like are prohibited in principle.', '* Please note that if you call or text overseas, there may be suspensions before the expiration date of the line or an additional fee.', '* To call internatiionally, please use third-party apps, such as Messenger, WhatsApp, and LINE, etc.\n', '* If you would like to add internation calling to your plan for an additional fee, please contact our customer service.']
                        }
                    ]
                },
                {
                    id: 8,
                    ref: 'eight',
                    question: '⯁Can my USA Phone Number be Transferred to Other USA Carriers',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['Unfortunately, you cannot transfer your phone number to other local telecommunications carriers']
                        }
                    ]
                },
            ],
            questionsEn: [
                {
                    id: 1,
                    question: "⯁I Can't Connect to Internet in the USA",
                    answers: [
                        {
                            id: 1,
                            title: 'For iPhone users',
                            notes: ['① Please check if you put the correct IMEI when you reserve the activation request.', '② If the WiFi is weak and hinders communication during the connection: Turn the Wifi off and restart.', '③ When "SIM is invalid" shows, it means that SIM lock is applied on your device. Remove the SIM card and go to "Settings" → "General" → "About" → "Carrier Lock".', '"④ If you are using a Japanese carrier, the carrier\'s profile may hinder T-Mobile\'s internet access. In this case, you need to delete Japanese carrier profiles to install T-Mobile.\n' +
                            'For iPhone users:\n' +
                            '""Settings"" → ""General"" → ""Profile"" or ""VPN""\n' +
                            ' If there is a Japanese carrier\'s profile, please delete it.\n' +
                            '"', ' [Note] App data such as emails and memos linked to Japanese profiles may be deleted together. We recommend that you use a cloud service app that is not related to Japanese telecommunications carriers such as Google. Please be assured that data unrelated to Japanese telecommunications carriers will not be deleted.', '⑤ If your device is not connected: Please contact us with your IMEI and explanations what are your problems and what you have tried so far.']
                        },
                        {
                            id: 2,
                            title: 'For Android users',
                            notes: ['① Please check if you put the correct IMEI when you reserve the activation request.', '② If the WiFi is weak and hinders communication during the connection: Turn the Wifi off and restart.', '"③If you are using a Japanese carrier, the carrier\'s profile may hinder T-Mobile\'s internet access. In this case, you need to delete Japanese carrier profiles to install T-Mobile.\n' +
                            '\n' +
                            'For Android users:\n' +
                            '""Settings"" → ""Network and Internet"" → ""Mobile network"" →Scroll down and tap  ""Access point name"" → If there is a Japanese carrier profile, please delete it."', '④ If yor device is not connected: Please contact us with your IMEI and explanations what are your problems and what you have tried so far.']
                        }
                    ]
                },
                {
                    id: 2, ref: 'second', question: '⯁Is my Device Supported', answers: [
                        {id: 1, title: '', notes: ['Please see "Check Compatible Device" page']}
                    ]
                },
                {
                    id: 3,
                    ref: 'third',
                    question: '⯁Where Can I Find my USA Phone Number',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['Phone numbers are automatically sorted on the day of activation. After arriving in the U.S., please check the number from the setting of your decvice.', '■For iPhone: "Settings" → "Cellular"', '■For Android: "Settings" → "About Phone" → "Phone number"', '* If you want to know the US phone number before traveling, set the start date before the actual travel date and contact our customer service. ']
                        }
                    ]
                },
                {
                    id: 4,
                    ref: 'fourth',
                    question: '⯁Does my area travel have internet access?',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['Please make sure to check the network status of your area ( by Coverage Map) before purchasing.']
                        }
                    ]
                },
                {
                    id: 5, ref: 'fifth', question: '⯁How Can I See if my Device in Unlocked', answers: [
                        {
                            id: 1,
                            title: '■ For iPhone ( * iOS14 or later):',
                            notes: ['Go to "Settings" → "General" → "About" → "Carrier Lock"', 'If it says [No SIM restrictions], then the SIM is unlocked.']
                        },
                        {
                            id: 2,
                            title: '■For  Xperia:',
                            notes: ['Enter "*#*#7465625#*#***" to the dial pad on your phone', 'Select "SIM lock," and if it shows ', '［］Network subset　→　SIM is unlocked', '［×］Network subset　→ SIM is locked and you need to unlock it']
                        },
                        {
                            id: 3,
                            title: '■For other Android:',
                            notes: ['Go to "Settings" → "About phone" → "SIM lock status" is [Locked] or [permitted], the SIM is unlocked.']
                        },
                    ]
                },
                {
                    id: 6, ref: 'sixth', question: '⯁Do I Need to Enable Data Roaming', answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['If you turn it on, the optimal radio waves where you are will be automatically selected, so you may be able to get a better internet connection.', 'Our plan includes data roaming, and there is no additional charge for it.']
                        }
                    ]
                },
                {
                    id: 7, ref: 'seventh', question: '⯁Can I Call Internationally', answers: [
                        {
                            id: 1,
                            title: '■ T-mobile',
                            notes: ['* Although there is also an Amazon product description, international calls to Japan and the like are prohibited in principle.', '* Please note that if you are transmitted overseas, there may be suspensions during the expiration date of the line or an additional fee.', '* If you use an application phone that does not use a telephone line (such as LINE phone or FaceTime), you will try to use the net instead of a telephone line, so you will be unlimited.', '* If you really want to use an international phone, you can use it after paying the option fee. For more information, please contact customer service.']
                        }
                    ]
                },
                {
                    id: 8,
                    ref: 'eight',
                    question: '⯁Can my USA Phone Number be Transferred to Other USA Carriers',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['No.', 'Unfortunately, you cannot transfer your phone number to other local telecommunications carriers']
                        }
                    ]
                },
                {
                    id: 9,
                    ref: 'ninth',
                    question: '⯁If If I have not removed carrier SIM lock?',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: [`If you have access to the internet, you can request to unlock your carrier lock through each Japanese carrier company's website.`]
                        }
                    ]
                },
                {
                    id: 10,
                    ref: 'tenth',
                    question: '⯁What if I lost my T-Mobile SIM card?',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: [`Unfortunately, you cannot use our service if you lose your SIM card. Please purchase a new one and submit the activation request form again.`]
                        }
                    ]
                },
            ],
            questionsForTelecom: [
                {
                    id: 1,
                    question: '⯁ 現地でつながらない時の対処法',
                    answers: [
                        {
                            id: 1,
                            title: 'iPhoneをご利用の方',
                            notes: ['① よくある間違い：ご予約の SIM番号と実際お手元のSIMカードの 番号に相違がない事お確かめください。', '② 接続中WiFiが微弱で通信を妨げている場合：WiFiをOFFにし、再起動をお試しください。', '③「SIMが無効」という画面が出て進まない場合：SIMロックがかかってます。SIMカードを取外し、「設定」→「 一般」→「 情報」→「SIMロック」をご確認ください。', '④ 日本のプロファイルが妨害しているケース：日本のプロファイルがアメリカの電波の妨げになり、繋がらない事がございます。その場合は日本のプロファイル削除とアメリカのプロファイルのインストールで解決できます。\n' +
                            '「設定」→「一般」→「プロファイル」もしくは「vpn」に日本のプロファイルがありましたら削除し、再起動をお試しください。\n' +
                            '\n', '【注意】日本のプロファイルに紐づいておりますメールやメモ等アプリデータも一緒に削除される可能性がございます。大事なデータはGoogleなど日本の通信事業者に関係のないクラウドサービスアプリをご利用される事をお勧め致します。その他、日本の通信事業者に関係のないデータは削除されませんのでご安心ください。\n'
                            , '⑤ご利用の端末がインターネットに接続されない場合：IMEIとともにお問い合わせください。']
                        },
                        {
                            id: 2,
                            title: 'Andoroidをご利用の方',
                            notes: ['① よくある間違い：ご予約の SIM番号と実際お手元のSIMカードの 番号に相違がない事お確かめください。', '② 接続中WiFiが微弱で通信を妨げている場合：WiFiをOFFにし、再起動をお試しください。', '③ 日本のAPNがございましたら全て削除し、再起動お試しくださいをお試しください。\n' +
                            '\n' +
                            '「設定」→「ネットワークとインターネット」→「モバイルネットワーク」→（下までスクロールして）「アクセスポイント名」\n' +
                            '日本のキャリアのプロファイルがありましたら削除し、再起動。', '④ご利用の端末がインターネットに接続されない場合：IMEIとともにお問い合わせください。']
                        }
                    ]
                },
                {
                    id: 2, ref: 'second', question: '⯁SIMカードに対応しているデバイスはどれですか？', answers: [
                        {id: 1, title: '', notes: ['「ご利用可能なデバイス」ページにてご確認ください。']}
                    ]
                },
                {
                    id: 3,
                    ref: 'third',
                    question: '⯁いつ、どこで米国の電話番号を知ることができますか？',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['開通時に自動で電話番号が振り分けられます。お手数ですが、現地到着後、端末の設定画面より番号をご確認ください。', '■iPhoneの場合：「設定」→「電話」', '■Androidの場合：「設定 」→「端末情報」→「端末の状態」、または「SIMのステータス」', '*旅行前に米国の電話番号を知りたい場合は、開通リクエストにて、実際のご利用日より前を開始日としてお選びください。']
                        }
                    ]
                },
                {
                    id: 4,
                    ref: 'fourth',
                    question: '⯁旅行先でインターネットを利用できますか？',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['以下のカバレッジマップでご旅行先の地域のネットワークステータスをご確認ください。']
                        }
                    ]
                },
                {
                    id: 5, ref: 'fifth', question: '⯁SIMロックを確認する方法は？', answers: [
                        {
                            id: 1,
                            title: '■iPhoneの場合（ * iOS14以降）：',
                            notes: ['「設定」→「一般」→「情報」→「SIMロック」が【SIMロックなし】であればSIMロック解除されています。\n' +
                            '[SIM制限なし]と書かれている場合、SIMはロック解除されます。']
                        },
                        {
                            id: 2,
                            title: '■Xperiaの場合：',
                            notes: ['「電話」のダイヤルパッドに「 *#*#7465625#*#* 」と入力', '「SIMロック」画面が表示され、ネットワークの項目に「ロック解除されてます」と表示されていましたらSIMロックは解除されています']
                        },
                        {
                            id: 3,
                            title: '■他のAndroidの場合：',
                            notes: ['「設定」→「端末の状態」→「SIMロック状態」が【ロック解除されています】や【許可】になっていればSIMロックは解除されています']
                        },
                    ]
                },
                {
                    id: 6, ref: 'sixth', question: '⯁データのローミングをオンにする必要がありますか？', answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['弊社の商品は全て現地キャリアの純正品となります為、データローミングはOFFでご利用いただけます。']
                        }
                    ]
                },
                {
                    id: 7, ref: 'seventh', question: '⯁ 日本に電話できますか？', answers: [
                        {
                            id: 1,
                            title: '■T-Mobile',
                            notes: ['* 日本への発信は原則として禁止されています。', '※海外へ発信されますと回線のご利用期限途中での停止や、追加料金が発生する可能性がありますのでご注意下さい。', '※電話回線を使用しないアプリ電話等（LINE電話やFacetimeなど）でしたら電話回線ではなくネット回線をしようしますので、無制限使い放題となります。', '※どうしても国際電話を使用されたい場合はオプション料金支払い後に利用可能です。詳しくはカスタマーサービスにお問い合わせください。']
                        }
                    ]
                },

                {
                    id: 9,
                    ref: 'ninth',
                    question: '⯁ 日本でSIMロックを解除してこなかった場合',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['米国でもネットに接続が出来る環境があれば、各キャリアサイトからSIMロック解除手続きが可能です。']
                        }
                    ]
                },
                {
                    id: 10,
                    ref: 'tenth',
                    question: '⯁T-MobileのSIMカードを紛失した場合',
                    answers: [
                        {
                            id: 1,
                            title: '',
                            notes: ['残念ながら弊社SIMカードを紛失された場合、サービスは利用不可能となります。 お手数ですが新しくSIMカードをご購入ください。']
                        }
                    ]
                },
            ],
        }
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },
}
</script>

<style lang="scss">
.faq {
    padding-top: 60px;
    background-color: #7CCAFF;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h2 {
        margin: 0;

    }

    p {
        margin: 0;
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
    }

    &__questions {
        margin-bottom: 20px;

        h2 {
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                color: #7CCAFF;
            }

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    &__answers {
        h4 {
            background-color: #7CCAFF;
            color: #fff;
            padding: 5px;
        }

        &-title {
            margin-bottom: 20px;

            p {
                margin-bottom: 10px;
            }

            img {
                max-width: 438px;
                width: 100%;
            }
        }
    }
}
</style>