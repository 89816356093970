<template>
    <div class="activation__result">
        <div>
            <div class="activation__result-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>

            </div>

            <div class="activation__result-message">
                <span>
                    Your activation request created successfully!
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActivationResult"
}
</script>

<style lang="scss">
    .activation {
        &__result {
            padding: 2rem;
            width: 100%;
            height: 500px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            &-icon {
                width: 100%;
                text-align: center;

                svg {
                    color: #6FE019;
                    height: 10rem;
                }
            }

            &-message {
                text-align: center;

                span {
                    color: #6FE019;
                    font-size: 1.3rem;
                    font-weight: 700;
                }
            }
        }
    }
</style>