<template>
    <app-layout>
        <div class="activation">
            <div class="activation__wrap">
                <form-layout>
                    <router-view></router-view>
                </form-layout>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import FormLayout from "@/layouts/FormLayout";

export default {
    name: "ActivationIndex",

    components: {FormLayout, AppLayout},

    data() {
        return {
            stage: 'form',
            form: {
                number: '',
                email: '',
                start_date: '',
                end_date: '',
                days: 0,
            }
        }
    },
}
</script>

<style lang="scss">
.activation {
    background: #7CCAFF;
    margin: -1px 0 0 0;

    &__wrap {
        margin: auto;
        max-width: 1300px;
        padding: 0 20px;
    }

    &__progress {
        margin: 0 0 2rem 0;

        img {
            width: 100%;
        }
    }
}
</style>