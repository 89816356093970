<template>
    <app-layout>
        <div class="activation">
            <div class="activation__wrap">
                <form-layout>
                    <router-view></router-view>
                </form-layout>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import FormLayout from "@/layouts/FormLayout";

export default {
    name: "Index",

    components: {FormLayout, AppLayout},
}

</script>

<style scoped>

</style>