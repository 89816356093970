import {createStore} from 'vuex'

export default createStore({
    state: {
        activationFormData: null,
        rechargeFormData: null,
    },

    mutations: {
        setActivationFormData(state, payload) {
            state.activationFormData = payload;
        },
        setRechargeFormData(state, payload) {
            state.rechargeFormData = payload;
        }
    },

    actions: {
        setActivationFormData({commit}, payload) {
            commit('setActivationFormData', payload);
        },
        setRechargeFormData({commit}, payload) {
            commit('setRechargeFormData', payload);
        },
        clearRechargeFormData({commit}) {
            commit('setRechargeFormData', null);
        },
    },

    getters: {
        activationFormData (state) {
            return state.activationFormData;
        },
        rechargeFormData (state) {
            return state.rechargeFormData;
        }
    },

    modules: {}
})
