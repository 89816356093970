<template>
    <footer class="footer">
        <div class="footer__wrap">
            <span>Contact Us Private Policy Terms and Conditions</span>
            <span>© 2023 GTT Mobile All Rights Reserved</span>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss">
    .footer {
        background: #7CCAFF;
        padding: 5rem 0;

        &__wrap {
            max-width: 1250px;
            padding: 6rem 50px 0 50px;
            margin: auto;
        }

        span {
            display: inline-block;
            width: 100%;
            margin: 0 0 1rem 0;
        }
    }
</style>