<template>
    <div class="form-layout__container">
        <ul class="form-layout__navs">
            <li
                class="form-layout__nav"
                :class="{'active': ['activation', 'activation_form', 'activation_details', 'activation_result'].includes($route.name)}"
            >
                <router-link to="/activation">
                    Activation
                </router-link>
            </li>
            <li
                class="form-layout__nav"
                :class="{'active': ['recharge', 'recharge_form', 'recharge_details', 'recharge_payment', 'recharge_result'].includes($route.name)}"
            >
                <router-link to="/recharge">
                    Recharge
                </router-link>
            </li>
        </ul>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "FormLayout"
}
</script>

<style lang="scss">
    .form-layout {
        &__container {
            background: #fff;
            border-radius: 10px;
            display: flex;
            overflow: hidden;
        }

        &__navs {
            padding: 0;
            margin: 0;
            list-style: none;
            max-width: 300px;
            box-shadow: 10px 0 5px -2px rgba(7,6,36, .1);
        }

        &__nav {
            padding: 1rem 4rem;
            border-bottom: 1px solid #eee;
            transition: .2s;

            a {
                text-decoration: none;
                font-size: 1.2rem;
                font-weight: 700;
                color: #1094EB;
                transition: .2s;
            }

            &:hover, &.active {
                background: #1094EB;
                border-bottom: 1px solid #1094EB;

                a {
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .form-layout {
            &__navs {
                display: none;
            }
        }
    }
</style>