<template>
    <div class="activation__details">
        <div class="activation__progress">
            <img src="../../assets/images/activation-progress-2.png" alt="">
        </div>

        <div class="activation__details-title">
            <h1>Activation Summary:</h1>
        </div>

        <div class="activation__details-img mobile">
            <img src="../../assets/images/sim-card-explained-en.png" alt="">
        </div>

        <div
            v-if="activationFormData"
            class="activation__details-items"
        >
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Insert Activation Code
                </div>
                <div class="activation__details-item-des">
                    {{ activationFormData.iccid }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Email
                </div>
                <div class="activation__details-item-des">
                    {{ activationFormData.email }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Start Date
                </div>
                <div class="activation__details-item-des">
                    {{ activationFormData.start_date }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    End Date
                </div>
                <div class="activation__details-item-des">
                    {{ activationFormData.end_date }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-title">
                    Days
                </div>
                <div class="activation__details-item-des">
                    {{ activationFormData.available_days }}
                </div>
            </div>
            <div class="activation__details-item">
                <div class="activation__details-item-button">
                    <template v-if="loading">
                        <button
                            disabled
                        >
                            Loading...
                        </button>
                    </template>
                    <template v-if="!loading">
                        <button
                            @click.prevent="submit"
                        >
                            Activate
                        </button>
                    </template>
                </div>
            </div>
        </div>

        <div class="activation__details-img">
            <img src="../../assets/images/sim-card-explained-en.png" alt="">
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "ActivationDetails",

        data() {
            return {
                activationFormData: null,
                loading: false,
            }
        },

        created() {
            this.initData();
        },

        methods: {
            initData() {
                let activationFormData = this.$store.getters.activationFormData;

                if (activationFormData) {
                    this.activationFormData = activationFormData;
                    this.activationFormData.start_date = moment(activationFormData.start_date).format('YYYY-MM-DD');
                    this.activationFormData.end_date = moment(activationFormData.end_date).format('YYYY-MM-DD');
                } else {
                    this.$router.push({name: 'activation_form'});
                }
            },

            submit() {
                this.loading = true;

                this.$axios.put(`/sim-card-activations/${this.activationFormData.id}/process`)
                    .then(res => {
                        if (res) {
                            this.$store.dispatch('setActivationFormData', res.data);
                            localStorage.setItem('activationFormData', JSON.stringify(res.data));
                            this.$router.push({name: 'activation_result'});
                        }

                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);

                        this.loading = false;
                    });
            }
        },
    }
</script>

<style lang="scss">
    .activation__details {
        padding: 2rem 4rem;
        display: flex;
        flex-wrap: wrap;

        &-title {
            width: 100%;
            margin: 0 0 2rem 0;

            h1 {
                margin: 0;
                font-size: 1.5rem;
                font-weight: 400;
            }
        }

        &-items {
            width: 40%;
            margin: 0 2rem 0 0;
        }

        &-item {
            margin: 0 0 1rem 0;

            &-title {
                display: inline-block;
                margin: 0 0 .5rem 0;
                color: #686868;
                font-weight: 700;
                font-size: .9rem;
            }

            &-des {
                font-size: 1rem;
                font-weight: 700;
                color: #000;
            }

            &-button {
                button {
                    background: #1094EB;
                    border: 1px solid #1094EB;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 500;
                    border-radius: 5px;
                    padding: .7rem .8rem;
                    width: 100%;
                    cursor: pointer;
                }
            }
        }

        &-img {
            width: 50%;

            &.mobile {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .activation {
            &__details {
                padding: 2rem 2rem;

                &-items {
                    width: 100%;
                    margin: 0;
                }

                &-img {
                    display: none;
                    width: 100%;

                    &.mobile {
                        display: block;
                    }
                }
            }
        }
    }
</style>