<template>
    <main class="app-layout">
        <navbar />

        <header-comp v-if="isEsimRoute" />

        <slot></slot>

        <footer-comp />
    </main>
</template>

<script>
import Navbar from "@/components/Navbar";
import HeaderComp from "@/components/Header";
import FooterComp from "@/components/Footer";

export default {
    name: "AppLayout",
    components: {
        Navbar,
        HeaderComp,
        FooterComp
    },
    computed: {
        isEsimRoute() {
            // Проверяем, содержит ли текущий маршрут "/esim" в пути
            return this.$route.path.indexOf('/esim') === -1 && this.$route.path.indexOf('/telecomsquare') === -1 && this.$route.path.indexOf('/entelecomsquare') === -1;
        },
    },
}
</script>

<style lang="scss">

</style>