import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

import ActivationIndex from "@/views/Activation/Index";
import ActivationForm from "@/views/Activation/ActivationForm";
import ActivationDetails from "@/views/Activation/ActivationDetails";
import ActivationResult from "@/views/Activation/ActivationResult";

import RechargeIndex from "@/views/Recharge/Index";
import RechargeForm from "@/views/Recharge/RechargeForm";
import RechargeDetails from "@/views/Recharge/RechargeDetails";
import RechargePayment from "@/views/Recharge/RechargePayment";
import RechargeResult from "@/views/Recharge/RechargeResult";
import Amazon from "../components/Amazon";
import EsimIndex from "@/views/Esim/Index";
import HowToAdd from "@/views/Esim/HowToAdd";
import EsimHome from "@/views/Esim/Home";
import Esim from "@/views/Esim/Esim";
import HowItWorks from "@/views/Esim/HowItWorks";
import CompatibleDevices from "@/views/Esim/CompatibleDevices";
import Faq from "@/views/Esim/Faq";
import Apn from "@/views/Esim/Apn";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: (to, from) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            if (params && params.redirect_status === "succeeded") {
                return { path: '/recharge/result', query: params }
            }

            return true;
        },
    },
    {
        path: '/plans',
        name: 'home_plans',
        component: Home,
    },

    {
        path: '/activation',
        name: 'activation',
        redirect: {name: 'activation_form'},
        component: ActivationIndex,
        children: [
            {
                path: 'form',
                name: 'activation_form',
                component: ActivationForm,
            },
            {
                path: 'details',
                name: 'activation_details',
                component: ActivationDetails,
            },
            {
                path: 'result',
                name: 'activation_result',
                component: ActivationResult,
            },
        ]
    },
    {
        path: '/recharge',
        name: 'recharge',
        redirect: {name: 'recharge_form'},
        component: RechargeIndex,
        children: [
            {
                path: 'form',
                name: 'recharge_form',
                component: RechargeForm,
            },
            {
                path: 'details',
                name: 'recharge_details',
                component: RechargeDetails,
            },
            {
                path: 'payment',
                name: 'recharge_payment',
                component: RechargePayment,
            },
            {
                path: 'result',
                name: 'recharge_result',
                component: RechargeResult,
            },
        ],
    },
    {
        path: '/amazon',
        name: 'Amazon',
        component: Amazon
    },
    {
        path: '/esim',
        name: 'Esim',
        component: EsimIndex,
        children: [
            {
                path: 'howitworks',
                name: 'HowItWorks',
                component: HowItWorks
            },
            {
                path: 'home',
                name: 'EsimHome',
                component: EsimHome,
            },
            {
                path: 'faq',
                name: 'Faq',
                component: Faq,
            },
            {
                path: 'howtoadd',
                name: 'HowToAdd',
                component: HowToAdd,
            },
            {
                path: 'compatible',
                name: 'CompatibleDevices',
                component: CompatibleDevices,
            },
            {
                path: 'apn',
                name: 'Apn',
                component: Apn,
            },
            {
                path: '',
                name: 'Esim',
                component: Esim,
            },
        ],
    },
    {
        path: '/telecomsquare',
        name: 'Telecom',
        component: EsimIndex,
        children: [
            {
                path: 'home',
                name: 'TelecomHome',
                component: EsimHome
            },
            {
                path: 'howitworks',
                name: 'TelecomHowItWorks',
                component: HowItWorks
            },
            {
                path: '',
                name: 'Telecom',
                component: Esim,
            },
            {
                path: 'compatible',
                name: 'TelecomCompatibleDevices',
                component: CompatibleDevices,
            },
            {
                path: 'faq',
                name: 'TelecomFaq',
                component: Faq,
            },
            {
                path: 'apn',
                name: 'TelecomApn',
                component: Apn,
            },
        ],
    },
    {
        path: '/entelecomsquare',
        name: 'EnTelecom',
        component: EsimIndex,
        children: [
            {
                path: 'home',
                name: 'EnTelecomHome',
                component: EsimHome
            },
            {
                path: 'howitworks',
                name: 'EnTelecomHowItWorks',
                component: HowItWorks
            },
            {
                path: '',
                name: 'EnTelecom',
                component: Esim,
            },
            {
                path: 'compatible',
                name: 'EnTelecomCompatibleDevices',
                component: CompatibleDevices,
            },
            {
                path: 'faq',
                name: 'EnTelecomFaq',
                component: Faq,
            },
            {
                path: 'apn',
                name: 'EnTelecomApn',
                component: Apn,
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
