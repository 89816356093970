<template>
    <app-layout>
        <div class="how-it">
            <div class="how-it__wrapper">
                <h1 ref="how-it-works"
                >{{ currentRoute === 'telecomsquare' ? 'ご利用までの流れ' : 'How it works' }}</h1>
                <hr>
                <div class="how-it__wrapper__container">
                    <div>
                        <div style="margin-bottom: 20px"
                             v-for="step in currentRoute === 'esim' ? steps : currentRoute === 'entelecomsquare' ? stepsForTelecomSquareEn : stepsForTelecomSquareJP"
                             class="how-it__step">
                            <div class="how-it__step__line">
                                <p>{{ step.id }}</p>
                            </div>
                            <div class="how-it__step__content">
                                <div>
                                    <h2>{{ step.title }}</h2>
                                    <p style="margin-bottom: 5px" v-for="(note, i) in step.notes"><span
                                        v-if="step.id === 1">{{ i + 1 }}) </span>{{ note }}</p>
                                    <p v-if="step.id === 3 && currentRoute === 'esim'">**You will get an American phone number, which will be assigned automatically on your requested activation day. </p>
                                    <p style="color: #F30B0B" v-if="step.id === 3 && currentRoute === 'esim'">If you want to get your American phone number before your departure, please select a start date earlier than your actual travel date.</p>
                                    <p v-if="step.id === 4 && currentRoute === 'esim'">**If you do not receive the notification, please see the
                                        <router-link to="/esim/howtoadd">"How to add eSIM"</router-link> page to add the eSIM manually</p>
                                </div>
                                <button @click="redirectToLink(step)" class="how-to-btn" v-if="step.btn">{{ step.btn }}</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentRoute === 'esim'">
                        <img  src="@/assets/images/manual.jpg" alt="">
                        <p style="font-size: 14px;" >*The manual might looks different</p>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "HowItWorks",
    components: {AppLayout},
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: 'Device checklist',
                    notes: ['Compatibility', 'SIM Unlocked', 'eSIM Compatible'],
                    // btn: 'ORDER NOW',
                    link: 'https://www.amazon.ca/dp/B0CFGK5ZGB?ref=myi_title_dp'
                },
                {
                    id: 2,
                    title: 'Receive the eSIM Activation Manual',
                    notes: ['You will receive the eSIM manual.', 'You WILL NOT get any physical SIM card!']
                },
                {
                    id: 3,
                    title: 'Submit Activation Request Form (Before your Departure)',
                    notes: ['Once you have received the eSIM manual, submit an activation request to activate your eSIM'],
                    btn: 'Reserve Now',
                    link: 'gosimcard.com/esim'
                },
                {
                    id: 4,
                    title: 'Add eSIM to your phone (on the Date Requested)',
                    notes: ['After activating your eSIM, you will receive an Activation Confirmation email containing your new USA phone number.', '*Ensure that you have strong internet connection', 'You will receive a notification prompting your phone to add the eSIM']
                },
            ],
            stepsForTelecomSquareJP: [
                {
                    id: 1,
                    title: '端末の確認（ご利用可能なデバイス参照）',
                    notes: ['ご利用端末がT-MobileSIMカードに対応しているかどうか。', 'ご利用端末のSIMロックが解除されているかどうか。']
                },
                {
                    id: 2,
                    title: 'SIMカードの開通を予約する',
                    notes: ['「開通リクエスト」ページよりSIMカードの開通を予約してください。', '予約が受理されるのに最大１２時間かかる場合がございます。できる限りお早めにリクエストしていただきますようお願い申し上げます。']
                },
                {
                    id: 3,
                    title: '開通完了メールを受け取る',
                    notes: ['SIMカードが開通されますと、アメリカの電話番号を含んだ、開通完了のお知らせメールが届きますのでご確認ください。']
                },
                {
                    id: 4,
                    title: 'アメリカ到着後、SIMカードをご利用端末に挿入する',
                    notes: ['到着後以下の設定を必ず行ってください。', '① 機内モードOFF', '\n' +
                    '② データ通信ON', '③ 端末の再起動"', '※上記の設定を行ってもご利用できない場合は、APN（モバイルネットワーク）設定を行う必要がある場合がございます。詳しい設定方法は「APN設定方法」をご参考ください。', 'また、ご使用端末に沿った具体的なAPN設定方法は以下のT-Mobile公式ページの説明（英語）をご参考ください。',
                        'https://www.t-mobile.com/support/tutorials/',
                        '（ご使用の機種を選択後、"APN"と検索ください）']
                },
            ],
            stepsForTelecomSquareEn: [
                {
                    id: 1,
                    title: 'Check your device',
                    notes: ['1) If the device is compatible with the T-mobile SIM card.', 'if carrier SIM lock is unlocked'],
                },
                {
                    id: 2,
                    title: 'Receive the SIM card Manual',
                    notes: ['Please reserve the SIM activation request through this website.', '*It may take up to 12 hours for reservations to be accepted. Please request as soon as possible.']
                },
                {
                    id: 3,
                    title: 'Receive Activation Confirmation Email',
                    notes: ['After activate your SIM card, we will send you an Activation Confirmation maile with your Amarican phone number. Please check the email.']
                },
                {
                    id: 4,
                    title: 'After arriving in the United States, insert the SIM card into your phone',
                    notes: ['"Please make the following settings after arrival.',
                        '①Airplane mode: OFF',
                        '② Cellular data: ON',
                        '③ Restart your phone"',   '*If you cannot use the internet after applying the settings above, you may need to do APN (Access Point Network) setting. For detailed instructions for APN settings, please visit the "APN settings" page on this website.', '"If you want to see the instructions for your device model, please visit the T-Mobile official website\'s tutorial page.\n' +
                        'https://www.t-mobile.com/support/tutorials/\n' +
                        '"']
                },
            ],
            currentRoute: ''
        }
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },
    methods: {
        redirectToLink(step) {
            if (step.link && step.id === 1) {
                window.location.href = step.link;
            } else {
                this.$router.push('/esim')
            }
        },
    },
}
</script>

<style lang="scss">
.how-it {
    background-color: #7CCAFF;
    padding-top: 60px;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h1 {
        margin: 0;

    }

    h2 {
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    p {
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @media screen and (max-width: 768px) {
                justify-content: center;
            }
        }
    }

    &__step {
        display: flex;
        align-items: center;
        gap: 20px;

        &__line {
            gap: 10px;
            align-items: center;
            display: flex;

            p {
                font-size: 80px;
                color: #1094EB;
                @media screen and (max-width: 768px) {
                    font-size: 56px;
                }
            }
        }
        &__content {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 20px;
            div {
                max-width: 700px;
            }
            @media screen and (max-width: 768px) {
                gap: 10px;
            }
        }
        .how-to-btn {
            background: #1094EB;
            border: 1px solid #1094EB;
            color: #fff;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 5px;
            padding: .7rem .8rem;
            cursor: pointer;
            white-space: nowrap;
        }
    }
}
</style>