<template>
    <header class="header">
        <div class="header__wrap">
            <div class="header__img">
                <img src="../assets/images/header-sims.png" alt="Sim cards">
            </div>
            <div class="header__text">
                <div>
                    <div class="header__text-title">
                        <h1>Prepaid sim</h1>
                    </div>
                    <div class="header__text-buttons">
                        <router-link to="/activation/form">Activation</router-link>
                        <router-link to="/recharge">Recharge</router-link>
                    </div>
                </div>
                <div class="header__text-5g">
                    5G <br> LTE
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style lang="scss">
    .header {
        width: 100%;
        position: relative;
        height: calc(100vh - 100px);
        background-image: url("../assets/images/header-bg-2.svg");
        background-size: cover;
        background-position: bottom 0 right 0;
        background-repeat: no-repeat;

        &__wrap {
            margin: auto;
            max-width: 1000px;
            padding: 6rem 50px 0 50px;
            display: flex;
        }

        &__img {
            img {
                height: 100%;
            }
        }

        &__text {
            display: flex;
            align-items: center;
            padding: 0 0 0 3rem;

            &-title {
                display: flex;

                h1 {
                    font-size: 5rem;
                    margin: 0;
                    text-transform: uppercase;
                }
            }

            &-buttons {
                display: flex;
                padding: 1rem 0 0 0;

                a {
                    display: inline-block;
                    height: auto;
                    color: #fff;
                    background: #1094EB;
                    border-radius: 100px;
                    padding: 1rem 5rem;
                    text-decoration: none;
                    margin: 0 .5rem 0 0;
                    font-size: 1.4rem;
                }
            }

            &-5g {
                color: #F30B0B;
                font-size: 3rem;
                font-weight: 700;
                margin: 0;
                text-transform: uppercase;
            }
        }
    }


    @media (max-width: 1400px) {
        .header {
            &__text {
                padding: 0 0 0 2rem;

                &-title {
                    h1 {
                        font-size: 3.8rem;
                    }
                }

                &-buttons {
                    a {
                        padding: .8rem 3.2rem;
                        font-size: 1.4rem;
                    }
                }

                &-5g {
                    font-size: 2.8rem;
                }
            }
        }
    }

    @media (max-width: 1100px) {
        .header {
            &__img {
                height: 300px;

                img {
                    height: 100%;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .header {
            &__wrap {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    @media (max-width: 635px) {
        .header {
            &__img {
                height: 200px;
                margin: 0 0 3rem 0;
            }

            &__text {
                &-title {
                    h1 {
                        font-size: 2.3rem;
                    }
                }

                &-buttons {
                    a {
                        padding: .6rem 1.6rem;
                        font-size: .9rem;
                    }
                }

                &-5g {
                    padding: 0 0 0 .6rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
</style>