<template>
  <router-view/>
</template>

<script>
export default {
    name: 'App',

    created() {
        // window.location.href = 'https://simlink.org/';
    },

    mounted() {
        let iframes = document.getElementsByTagName('body')[0].getElementsByTagName('iframe');
        if (iframes) {
            iframes[0].style.display = 'none'
        }
    },
}
</script>

<style lang="scss">
    body {
        margin: 0;
        padding: 0;
    }

    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
</style>
