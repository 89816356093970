<template>
    <nav class="navbar">
        <div class="navbar__wrap">

            <!-- Mobile burger -->
            <div
                class="navbar__burger"
                @click.prevent="toggleMobileMenu"
            >
                <template v-if="!showMobileMenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.514" height="13.133" viewBox="0 0 22.514 13.133">
                        <path id="Path_39" data-name="Path 39" d="M4.5,21.195a.938.938,0,0,1,.938-.938H26.076a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,21.195Zm0-5.628a.938.938,0,0,1,.938-.938H26.076a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,15.567Zm0-5.628A.938.938,0,0,1,5.438,9H26.076a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,9.938Z" transform="translate(-4.5 -9)" fill-rule="evenodd"/>
                    </svg>
                </template>

                <template v-if="showMobileMenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.514" height="13.133" viewBox="0 0 22.514 13.133">
                        <path id="Path_39" data-name="Path 39" d="M4.5,21.195a.938.938,0,0,1,.938-.938h5.628a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,21.195Zm0-5.628a.938.938,0,0,1,.938-.938H18.571a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,15.567Zm0-5.628A.938.938,0,0,1,5.438,9H26.076a.938.938,0,1,1,0,1.876H5.438A.938.938,0,0,1,4.5,9.938Z" transform="translate(-4.5 -9)" fill-rule="evenodd"/>
                    </svg>
                </template>
            </div>

            <!-- Mobile menu -->
            <ul
                v-if="showMobileMenu"
                class="navbar__mobile-menu"
                :class="{'fadeIn': showMobileMenu}"
            >
<!--                <li class="navbar__mobile-menu-item navbar__mobile-menu-item&#45;&#45;primary">-->
<!--                    <router-link to="/plans">-->
<!--                        Plans-->
<!--                    </router-link>-->
<!--                </li>-->
                <li class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/home`">
                        Home
                    </router-link>
                </li>
                <li class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/howitworks`">
                        {{ currentRoute === 'telecomsquare' ? 'ご利用までの流れ' :  ' How it works'  }}

                    </router-link>
                </li>
                <li class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}`">
                        {{ currentRoute === 'telecomsquare' ? '開通リクエスト' :  'Activation Request' }}

                    </router-link>
                </li>
<!--                <li class="navbar__mobile-menu-item">-->
<!--                    <router-link to="/activation">-->
<!--                        Activation-->
<!--                    </router-link>-->
<!--                </li>-->
<!--                <li class="navbar__mobile-menu-item">-->
<!--                    <router-link to="/recharge">-->
<!--                        Recharge-->
<!--                    </router-link>-->
<!--                </li>-->
                <li class="navbar__mobile-menu-item" v-if="currentRoute !== 'telecomsquare' && currentRoute !== 'entelecomsquare'">
                    <router-link to="/esim/howtoadd">
                        How to add eSIM to your phone
                    </router-link>
                </li>
                <li class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/compatible`">
                        {{ currentRoute === 'telecomsquare' ? 'ご利用可能なデバイス' : ' Check Compatible Device' }}

                    </router-link>
                </li>
                <li v-if="currentRoute === 'telecomsquare' || currentRoute === 'entelecomsquare'" class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/apn`">
                        {{ currentRoute === 'telecomsquare' ?  'APN Settings' :' APN Settings'  }}
                    </router-link>
                </li>
                <li class="navbar__mobile-menu-item">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/faq`">
                        {{ currentRoute === 'telecomsquare' ? 'Q&A' :  ' FAQ' }}

                    </router-link>
                </li>
<!--                <li class="navbar__mobile-menu-item">-->
<!--&lt;!&ndash;                  <a target="_blank" href="http://simlink.org">&ndash;&gt;-->
<!--&lt;!&ndash;                    Amazon&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--                    <router-link to="/amazon">-->
<!--                        Amazon-->
<!--                    </router-link>-->
<!--                </li>-->
            </ul>

            <div class="navbar__logo" @click.prevent="$router.push({name: currentRoute === 'esim' ? 'EsimHome' : currentRoute === 'telecomsquare' ? 'TelecomHome' : currentRoute === 'entelecomsquare' ? 'EnTelecomHome' : 'home'})">
                <img v-if="currentRoute !== 'telecomsquare' && currentRoute !== 'entelecomsquare' && currentRoute !== 'esim'" src="../assets/images/logo.png" alt="Gosimcard">
                <div v-else-if="currentRoute === 'telecomsquare' || currentRoute === 'entelecomsquare'" class="navbar__logo__collaborated">
                    <img class="img_1" src="../assets/images/telecomsquare-logo.png" alt="Gosimcard">
                    <span>x</span>
                    <img class="img_2" src="../assets/images/GTT_LOGO.png" alt="Gosimcard">
                </div>
                <div v-else class="navbar__logo__esim">
                    <img class="img_1" src="../assets/images/esim-logo.png" alt="Gosimcard">
                    <img class="img_2" src="../assets/images/GMS-logo-color.png" alt="Gosimcard">
                </div>
            </div>

            <ul class="navbar__menu">
<!--                <li class="navbar__menu-item navbar__menu-item&#45;&#45;primary">-->
<!--                    <router-link to="/plans">-->
<!--                        Plans-->
<!--                    </router-link>-->
<!--                </li>-->
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/home`">
                        Home
                    </router-link>
                </li>
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/howitworks`">
                        {{ currentRoute === 'telecomsquare' ?  'ご利用までの流れ' :' How it works'  }}
                    </router-link>
                </li>
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}`">
                        {{ currentRoute === 'telecomsquare' ?  '開通リクエスト' :' Activation Request'  }}
                    </router-link>
                </li>
<!--                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles: ''">-->
<!--                    <router-link to="/activation">-->
<!--                        Activation-->
<!--                    </router-link>-->
<!--                </li>-->
<!--                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles: ''">-->
<!--                    <router-link to="/recharge">-->
<!--                        Recharge-->
<!--                    </router-link>-->
<!--                </li>-->
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''" v-if="currentRoute !== 'telecomsquare' && currentRoute !== 'entelecomsquare'">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : 'esim'}/howtoadd`">
                        How to add eSIM to your phone
                    </router-link>
                </li>
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/compatible`">
                        {{ currentRoute === 'telecomsquare' ?  'ご利用可能なデバイス' :' Check Compatible Device'  }}
                    </router-link>
                </li>
                <li v-if="currentRoute === 'telecomsquare' || currentRoute === 'entelecomsquare' || currentRoute === 'esim'" class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/apn`">
                        {{ currentRoute === 'telecomsquare' ?  'APN Settings' :' APN Settings'  }}
                    </router-link>
                </li>
                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles : ''">
                    <router-link :to="`/${currentRoute === 'telecomsquare' ? 'telecomsquare' : currentRoute === 'entelecomsquare' ? 'entelecomsquare' : 'esim'}/faq`">
                        {{ currentRoute === 'telecomsquare' ?  'Q&A' :' FAQ'  }}
                    </router-link>
                </li>
<!--                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles: ''">-->
<!--&lt;!&ndash;                  <a target="_blank" href="http://simlink.org">&ndash;&gt;-->
<!--&lt;!&ndash;                    Amazon&ndash;&gt;-->
<!--&lt;!&ndash;                  </a>&ndash;&gt;-->
<!--                    <router-link to="/amazon">-->
<!--                        Amazon-->
<!--                    </router-link>-->
<!--                </li>-->
<!--                <li class="navbar__menu-item" :style="currentRoute==='esim' ? esimStyles: ''">-->
<!--                    EN-->
<!--                </li>-->
            </ul>

<!--            <div class="navbar__lang">-->
<!--                <a href="">-->
<!--                    EN-->
<!--                </a>-->
<!--            </div>-->
        </div>
    </nav>
</template>

<script>
    export default {
        name: "Navbar",

        data() {
            return {
                showMobileMenu: false,
                currentRoute: '',
                esimStyles: {
                    fontSize: '1rem'
                }
            }
        },

        methods: {
            toggleMobileMenu() {
                this.showMobileMenu = !this.showMobileMenu;
            }
        },
        created() {
            const routeParts = this.$route.path.split('/');

            this.currentRoute = routeParts[1];
        },
    }
</script>

<style lang="scss">
    .navbar {
        height: 100px;
        box-shadow: 0 5px 6px rgba(7,6,36, .1);
        position: relative;

        &__wrap {
            max-width: 1410px;
            padding: 0 10px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        &__logo {
            img {
                cursor: pointer;
                max-width: 120px;
                @media screen and (max-width: 1200px) {
                    max-height: 50px;
                }

            }
            &__collaborated {
                display: flex;
                align-items: center;
                gap: 10px;
                .img_1 {
                    max-width: 120px;
                }
                .img_2 {
                    max-width: 90px;
                }
            }
            &__esim {
                display: flex;
                align-items: center;
                gap: 15px;
                img {
                    @media screen and (max-width: 768px) {
                        height: 45px;
                    }
                }
            }
        }

        &__menu {
            list-style: none;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            &-item {
                margin: 0 0 0 2.2rem;
                font-size: 1.2rem;
                font-weight: 700;
                color: #000;

                a {
                    text-decoration: none;
                    color: #000;
                }

                &--primary {
                    color: #fff;
                    background: #1094EB;
                    border-radius: 100px;
                    padding: .5rem 6rem;

                    a {
                        color: #fff;
                    }
                }
            }
        }

        &__burger, &__lang, &__mobile-menu {
            display: none;
        }
    }

    @media (max-width: 992px) {
        .navbar {
            height: 60px;
            padding: 0 1rem;

            &__burger {
                display: block;
            }

            &__mobile-menu {
                display: block;
                position: absolute;
                top: 60px;
                left: 0;
                width: 100%;
                background: #fff;
                list-style: none;
                margin: 0;
                padding: 0;
                z-index: 100;

                &-item {
                    font-size: 1rem;
                    font-weight: 500;
                    color: #000;
                    text-align: center;
                    padding: 1rem 0;
                    border-bottom: 1px solid rgba(112,112,112, .2);

                    a {
                        text-decoration: none;
                        color: #000;
                    }

                    &--primary {
                        border-top: 1px solid rgba(112,112,112, .2);

                        a {
                            color: #fff;
                            background: #1094EB;
                            border-radius: 100px;
                            padding: .2rem 3rem;
                            display: inline-block;
                        }
                    }
                }
            }

            &__lang {
                display: block;

                a {
                    font-weight: 700;
                    color: #000;
                    text-decoration: none;
                }
            }

            &__menu {
                display: none;
            }
        }
    }

    .fadeIn {
        animation: fadeIn .1s ease-in;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
</style>