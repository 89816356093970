<template>
    <app-layout>
        <div class="compatible">
            <div class="compatible__wrapper">
                <h1>{{
                        currentRoute === 'entelecomsquare' ? 'Is Your Device T-Mobile SIM card Compatible??' : currentRoute === 'telecomsquare' ? 'ご利用可能なデバイスについて' : 'Is Your Device eSIM Compatible??'
                    }}</h1>
                <hr>
                <template v-if="currentRoute === 'esim'">
                    <div class="compatible__notes">
                        <p style="font-size: 28px; margin: 0 0 15px 0">To check your device's eSIM compatibility status, please visit
                            <a href="https://prepaid.t-mobile.com/bring-your-own-device">T-Mobile</a> website.</p>
                        <p>
                            ・This product can only be used with smartphones that support eSIM and have unlocked the SIM, have confirmed the compatibility status on this page or Amazon product page. To check compatibility, please visit the T-Mobile website before completing this form. Please note that we cannot accept returns or refunds if the SIM lock cannot be unlocked, the compatibility conditions have not been confirmed, or only one compatible band is available.</p>
                        <p>**When you check your phone's compatibility on the T-Mobile website, it should look like the picture and clearly say, "Your delivery is fully compatible with the T-Mobile network."</p>
                        <p>**If  it shows "we can't confirm that this device is unblocked." Please contact your carrier company to make sure your SIM lock is unlocked or not</p>
                        <img style="display: flex; margin: 15px auto; max-width: 1000px; width: 100%" src="@/assets/images/compatible-pic.png" alt="">
                        <h2>Note:

                        </h2>
                        <p>・Not compatible with WiFi routers
                        </p>
                        <p>・The device must be unlocked.

                        </p>
                        <p>・All sales are final.</p>
                        <p style="color: #F30B0B">Please note that any iPhone purchased from mainland China, Hong Kong and Macao do NOT have eSIM capability</p>
                    </div>
                </template>
                <template v-if="currentRoute === 'telecomsquare'">
                    <h2 ref="compatible">◆iPhones</h2>
                    <hr>
                    <div class="how-to__notes">
                        <p>

                            SIM フリー or ロック解除済み iPhone であり以下のモデルでご利用いただけます。
                            iPhone XS and after (iPhone XS,iPhone ×S Max, iPhone 11, iPhone 11 Pro, iPhone SE 2 (2020)
                            iPhone 12, iPhone 12 Mini, iPhone 12 Pro, iPhone 12 Pro Max, iPhone 13 iPhone 13 Mini,
                            iPhone 13 Pro, iPhone 13 Pro Max, iPhone SE 3 (2022) iPhone 14, iPhone 14 Plus, iPhone 14
                            Pro, iPhone 14 Pro Max

                        </p>
                        <p style="font-weight: 900">ご利用可能な端末は予告なく変わる可能性がございます。以下のリンクにてご使用の端末がSIMカードに対応しているかご確認ください。
                        </p>
                        <p><a href="https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender">https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender</a>
                        </p>
                    </div>

                    <hr>
                    <h2>Android</h2>
                    <hr>
                    <div class="compatible__notes">
                        <p>以下のリンクにてご利用端末のIMEIを入力。結果が完全対応 (You're all set.) である事をご確認ください。
                        </p>
                        <p>https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender</p>
                    </div>
                    <div class="compatible__notes">
                        <p>※非対応確認済み端末：HUAWEIなどの中国産の端末、ASUS 全機種、SONY XPERIA 全機種
                        </p>
                        <p>注意：<br>
                            ・WiFiルーターにはご使用いただけません<br>
                            ・タブレットにはご使用いただけません<br>
                            ・ご使用端末のSIMロックが解除されている必要があります<br>


                        </p>

                    </div>
                </template>
                <template v-if="currentRoute === 'entelecomsquare'">
                    <h2 ref="compatible">iPhones</h2>
                    <hr>
                    <div class="how-to__notes">
                        <p>◆iPhones
                            SIM free or unlocked iPhone models iPhone XS and after (iPhone XS, iPhone ×S Max, iPhone 11,
                            iPhone 11 Pro, iPhone SE 2 (2020)
                            iPhone 12, iPhone 12 Mini, iPhone 12 Pro, iPhone 12 Pro Max, iPhone 13 iPhone 13 Mini,
                            iPhone 13 Pro, iPhone 13 Pro Max, iPhone SE 3 (2022) iPhone 14, iPhone 14 Plus, iPhone 14
                            Pro, iPhone 14 Pro Max)
                        </p>
                        <p>Compatible devices may change without notice. Please check if your device is compatible with the SIM card using the link below.
                            <a href="https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender">https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender</a></p>
                    </div>
                    <hr>
                    <h2>Android</h2>
                    <hr>
                    <div class="compatible__notes">
                        <p>Please visit the link below and make sure to check if your device is compatible or not.
                        </p>
                        <p><a href="https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender">https://www.t-mobile.com/resources/bring-your-own-phone#imeiextender</a>
                        </p>
                    </div>
                    <div class="compatible__notes">
                        <p>*Already confirmed non-compatible devices: Chinese devices such as HUAWEI, all ASUS models, all SONY XPERIA models</p>
                        <p>Note:<br>
                            ・Cannot be used for wifi routers<br>
                            ・The device must be unlocked. If the device is Android, please check with device setting<br>
                            for further detail
                        </p>

                    </div>
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "CompatibleDevices",
    components: {AppLayout},
    data() {
        return {
            currentRoute: ''
        }
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },
}
</script>

<style lang="scss">
.compatible {
    padding: 60px 0 150px 0;
    background-color: #7CCAFF;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h2 {
        margin: 0;

    }

    h1 {
        margin: 0;

    }

    p {
        margin: 0;
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
    }

    &__notes {
        h2 {
            margin-bottom: 8px;
            font-weight: 500;
            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        p {
            margin-left: 10px;
            margin-bottom: 5px;
        }
    }
}
</style>