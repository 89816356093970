import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "./configs/axios";
import VueSmoothScroll from 'vue3-smooth-scroll'

const app = createApp(App);

app.config.globalProperties.$axios = axios;

app
    .use(store)
    .use(router)
    .use(VueSmoothScroll)
    .mount('#app')
