<template>
    <app-layout>
        <div class="compatible">
            <div class="compatible__wrapper">
                <template v-if="currentRoute === 'telecomsquare'">
                    <h2 ref="compatible">◆iPhone / Android 共通設定</h2>
                    <hr>
                    <div class="how-to__notes">
                        <h3 style="font-weight: 700">SIMカードを挿入後、以下の設定を必ず行ってください。</h3>
                        <p>① 機内モードOFF</p>
                        <p>② データ通信ON </p>
                        <p>③ データローミングOFF​  </p>
                        <p>④ 端末の再起動 </p>
                        <p style="font-size: 24px; font-weight: 700">通常はAPN設定不要ですが、万が一自動で設定されない場合は以下をご参考ください。 </p>
                    </div>
                    <h2>◆APN設定
                        </h2>
                    <hr>
                    <div class="compatible__notes">
                        <p>共通設定に加えて、APN（モバイルネットワーク）設定が必要な場合もございます。
                        </p>
                        <p style="text-decoration: underline">- Androidをご利用の方</p>
                    </div>
                    <div class="compatible__notes">
                        <p>① 設定画面を開く</p>
                        <p>②「無線とネットワーク」→「モバイルネットワーク設定」</p>
                        <p>*端末によって表示が異なる可能性がございます。
                        </p>
                        <p>③「新しいAPN」もしくは、右上の＋ボタンを選択</p>
                        <p>④ 以下ご利用のSIMカードに合った情報を手入力</p>
                        <p style="margin-bottom: 20px;">「名前」 T-Mobile 「APN」 <a href="http://fast.t-mobile.com/">Fast.t-mobile.com</a></p>
                        <p style="text-decoration: underline">-ご利用の端末モデルに合った設定方法の説明（英語）</p>
                        <p>- ご利用端末モデルに合った設定方法の説明は、下記のT-Mobileの公式ホームページ（英語）にて、ご利用端末モデルを選択し、 “APN”と検索してください。</p>
                        <p><a href="https://www.t-mobile.com/support/tutorials/">https://www.t-mobile.com/support/tutorials/</a></p>
                    </div>
                </template>
                <template v-if="currentRoute === 'esim'">
                    <!-- <h1>APN Settings</h1>
                    <hr> -->
                    <h2 ref="compatible">◆iPhone / Android Common settings</h2>
                    <hr>
                    <div class="how-to__notes">
                        <h3 style="margin-bottom: 10px;">Please follow the steps below after inserting the SIM card into youe phone.</h3>
                        <p style="margin-bottom: 10px">① Turn Airplaine mode OFF </p>
                        <p style="margin-bottom: 10px">② Turn "Cellular data" ON  </p>
                        <p style="margin-bottom: 10px">③ Turn "Data roaming" ON​  </p>
                        <p style="margin-bottom: 10px">④ Restart your device </p>
                        <p>Typically, T-Mobile does not require APN settings. However, if you cannot use SIM card after following the instructions above, please follow the steps below.</p>
                    </div>
                    <hr>
                    <h2>◆APN Settings</h2>
                    <hr>
                    <div class="compatible__notes">
                        <p>APN (mobile network) settings might be needed.
                        </p>
                        <p style="text-decoration: underline">- Android users</p>
                    </div>
                    <div class="compatible__notes">
                        <p>① Open "Settings"</p>
                        <p>② "Wireless and Network" → "Mobile Network Settings"</p>
                        <p>* The screen name differs depending on the model of device.</p>
                        <p>③ Select "New APN" or + button on the upper right</p>
                        <p>④ Handle input information that matches the SIM card used below</p>
                        <p style="margin-bottom: 10px;">Name: "T-Mobile" APN: <a href="http://fast.t-mobile.com/">Fast.t-mobile.com</a></p>
                        <p>- Tutorials for your device's model</p>
                        <p>"Please visit T-Mobile's official website to see the instructions about APN settings for your device's model.
                            Choose your device's model and serce ""APN"""</p>
                        <p><a href="https://www.t-mobile.com/support/tutorials/">https://www.t-mobile.com/support/tutorials/</a></p>
                    </div>
                </template>
                 <template v-if="currentRoute === 'entelecomsquare'">
                    <h2 ref="compatible">◆iPhone / Android Common settings</h2>
                    <hr>
                    <div class="how-to__notes">
                        <h3>Please follow the steps below after inserting the SIM card into youe phone.</h3>
                        <p>① Turn Airplaine mode OFF </p>
                        <p>② Turn "Cellular data" ON  </p>
                        <p>③ Turn "Data roaming" ON​  </p>
                        <p>④ Restart your device </p>
                        <p>Typically, T-Mobile does not require APN settings. However, if you cannot use SIM card after following the instructions above, please follow the steps below.</p>
                    </div>
                    <hr>
                    <h2>◆APN Settings</h2>
                    <hr>
                    <div class="compatible__notes">
                        <p>APN (mobile network) settings might be needed.
                        </p>
                        <p style="text-decoration: underline">- Android users</p>
                    </div>
                    <div class="compatible__notes">
                        <p>① Open "Settings"</p>
                        <p>② "Wireless and Network" → "Mobile Network Settings"</p>
                        <p>* The screen name differs depending on the model of device.</p>
                        <p>③ Select "New APN" or + button on the upper right</p>
                        <p>④ Handle input information that matches the SIM card used below</p>
                        <p>Name: "T-Mobile" APN: <a href="http://fast.t-mobile.com/">Fast.t-mobile.com</a></p>
                        <p>- Tutorials for your device's model</p>
                        <p>"Please visit T-Mobile's official website to see the instructions about APN settings for your device's model.
                            Choose your device's model and serce ""APN"""</p>
                        <p><a href="https://www.t-mobile.com/support/tutorials/">https://www.t-mobile.com/support/tutorials/</a></p>
                    </div>
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: "Apn",
    components: {AppLayout},
    data() {
        return {
            currentRoute: ''
        }
    },
    created() {
        const routeParts = this.$route.path.split('/');

        this.currentRoute = routeParts[1];
    },
}
</script>

<style lang="scss">
.compatible {
    padding: 60px 0 150px 0;
    background-color: #7CCAFF;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    h2 {
        margin: 0;

    }
    h3 {
        margin: 0;
    }
    p {
        margin: 0;
    }

    &__wrapper {
        margin: auto;
        max-width: 1300px;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;
    }

    &__notes {
        h2 {
            margin-bottom: 8px;
            font-weight: 500;
            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        p {
            margin-left: 10px;
            margin-bottom: 5px;
        }
    }
}
</style>