<template>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";

export default {
    name: "Amazon.vue",
    components: {AppLayout},
    methods: {
        routeToAmazon() {
            // window.location.href = "https://simlink.org/"
        }
    },
    created() {
        this.routeToAmazon()
    },
    mounted() {
        let iframes = document.getElementsByTagName('body')[0].getElementsByTagName('iframe');
        if (iframes) {
            iframes[0].style.display = 'block'
        }
    },
    beforeUnmount() {
        let iframes = document.getElementsByTagName('body')[0].getElementsByTagName('iframe');
        if (iframes) {
            iframes[0].style.display = 'none'
        }
    }
}
</script>

<style scoped>

</style>