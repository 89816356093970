<template>
    <app-layout>
        <div class="home">
            <div class="home__wrap">
                <div class="home__title">
                    <h1>
                        All plans offer Unlimited text, talk & DATA
                        <span>5G</span>
                    </h1>
                </div>

                <div
                    ref="home-services"
                    class="home__services"
                >
                    <div class="home__service">
                        <div class="home__service-img">
                            <img src="../assets/images/ATA.png" alt="">
                        </div>
                        <div class="home__service-des">
                            <h3>AT&T Prepaid Voice & Data Plan</h3>
                            <p>
                                T-mobile Simply Prepaid Unlimited plan
                                Unlimited Talk, Text, & 4G LTE data
                                Data: Unlimited high-speed data
                                (prioritized...could slow down when congested.)
                                Canada Mexico Roaming INCLUDED
                                Tethering NOT INCLUDED
                                Unlimited text to over 230 countries.
                            </p>
                        </div>
                    </div>
                    <div class="home__service">
                        <div class="home__service-img">
                            <img src="../assets/images/T-Monile.png" alt="">
                        </div>
                        <div class="home__service-des">
                            <h3>T-mobile Prepaid Voice & Data Plan</h3>
                            <p>
                                T-mobile Simply Prepaid Unlimited
                                Unlimited Talk, Text, & 4G LTE data
                                Data: Unlimited high-speed data
                                (50GB fast speed deprioritized when the network Is busy.)
                                Canada Mexico Roaming NOT INCLUDED
                                Unlimited text to over 230 countries
                            </p>
                        </div>
                    </div>
                    <div class="home__service">
                        <div class="home__service-img">
                            <img src="../assets/images/Verizon.png" alt="">
                        </div>
                        <div class="home__service-des">
                            <h3>Verizon Prepaid Voice & Data Plan</h3>
                            <p>
                                Verizon PREPAID Unlimited $65plan
                                Unlimited Talk, Text, & 5G 4G LTE data
                                Data: Unlimited high-speed data
                                Tethering is NOT INCLUDED (Includes unlimited talk & text to and from Mexico and Unlimited texting to over 200 countries).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

export default {
    name: 'Home',

    components: {
        AppLayout,
    },

    mounted() {
        if(this.$route.name === 'home_plans') {
            this.$smoothScroll({
                scrollTo: this.$refs['home-services'],
                duration: 1000,
                offset: -50,
            });
        }
    },
}
</script>

<style lang="scss">
    .home {
        background: #7CCAFF;
        margin: -1px 0 0 0;

        &__wrap {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: auto;
            max-width: 1300px;
            padding: 50px 30px 0 30px;
        }

        &__title {
            background: #fff;
            display: inline-block;
            border-radius: 10px;
            padding: .5rem 2rem;
            margin: 0 0 100px 0;

            h1 {
                margin: 0;
                color: #7CCAFF;
                font-weight: 400;
                font-size: 1.5rem;

                span {
                    color: #F30B0B;
                    margin: 0 0 0 20px;
                }
            }
        }

        &__services {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__service {
            background: #fff;
            display: inline-block;
            border-radius: 10px;
            width: 420px;
            margin: 0 1rem 100px 0;

            &:last-child {
                margin-right: 0;
            }

            &-img {
                height: 150px;
                text-align: center;

                img {
                    height: 100%;
                    border-radius: 10px;
                    border: 7px solid #fff;
                    background: #fff;
                    margin: -50px 0 0 0;
                }
            }

            &-des {
                padding: 0 60px 2rem 60px;

                h3 {
                    font-weight: 700;
                    font-size: 20px;
                    text-align: center;
                }


            }
        }
    }

    @media (max-width: 635px) {
        .home {
            &__service {
                &-des {
                    padding: 0 20px;
                }
            }
        }
    }
</style>
