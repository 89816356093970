<template>
    <div class="recharge__result">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Checkmark Circle</title><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/></svg>

            <span>
                You successfully activated your sim card!
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "RechargeResult",

    created() {
        this.initResult();
    },

    methods: {
        initResult() {
            let routeQuery = this.$route.query;
            let rechargeFormData = localStorage.getItem('rechargeFormData');
            rechargeFormData = JSON.parse(rechargeFormData);

            if (routeQuery.redirect_status === "succeeded" && rechargeFormData && rechargeFormData.id) {
                let form = {
                    payment_intent: routeQuery.payment_intent,
                    payment_intent_client_secret: routeQuery.payment_intent_client_secret,
                };

                this.$axios.post(`/sim-card-recharges/${rechargeFormData.id}/recharge`, form)
                    .then(res => {
                        this.$store.dispatch('clearRechargeFormData');
                        localStorage.removeItem('rechargeFormData');
                    })
            }
        },
    }
}
</script>

<style lang="scss">
    .recharge {
        &__result {
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                display: flex;
                align-items: center;
            }

            svg {
                color: #6FE019;
                height: 1.5rem;
            }

            span {
                color: #6FE019;
                font-size: 1.3rem;
                font-weight: 700;
            }
        }
    }
</style>